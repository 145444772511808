.container {
    border: 3px solid #000;
    background-color: rgba(246, 221, 178, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  .header, .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
  .invoice-info, .bill-info {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .invoice-info div, .bill-info div {
    flex: 1;
    padding: 0 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: rgba(255, 0, 62, 0.8);
    color: white;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .signature img {
    height: 90px;
    width: 90px;
  }
  .footer-info {
    text-align: right;
    margin-top: 20px;
  }